<template>
    <div class="question-item">
        <h2 :required="required" :style="{ color: pageInfo.style.questionColor }">{{title}}</h2>
        <RadioGroup vertical :style="{ color: pageInfo.style.optionColor }">
            <Radio v-for="(option, index) in options" :key="index" :label="option" disabled></Radio>
            <Radio v-if="other" label="其他" disabled>
                <span>其他</span>
                <Input style="margin-left: 10px;width: 200px;" disabled />
            </Radio>
        </RadioGroup>

    </div>
</template>
<script>
export default {
    name: "SingleChoice",
    props: ["title",  "required", "options", "other", "pageInfo"]
}
</script>
<style lang="less" scoped>
   
</style>